.footerArea {
  height: 56px;
  background-color: rgba(56, 56, 56, 1);
}

.footer {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.smContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.impressumHeader {
  margin-left: 20px;
  cursor: pointer;
}

.datenschutzHeader {
  margin-left: 20px;
  cursor: pointer;
}

.arrow {
  margin-top: 5px;
  margin-left: 5px;
  width: 18px;
  height: auto;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.copyright {
  margin-right: 20px;
}

.impressumHeader:hover {
  color: rgba(255, 123, 115, 1);
}

.datenschutzHeader:hover {
  color: rgba(255, 123, 115, 1);
}

@media (max-width: 580px) {
  .footerArea {
    height: 80px;
  }
.footer {
  height: auto;
  flex-direction: column;
}
.footerLeft {
  padding-top: 10px;
}
.datenschutzHeader {
  margin-left: 0;
}
.impressumHeader {
  margin-left: 10px;
}
.copyright {
  margin: 10px 0;
}
}