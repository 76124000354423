.datenschutz {
    background-color: rgba(56, 56, 56, 1);
    color: white;
  }
  
  .datenschutzContainer {
    padding: 25px;
  }
  
  .datenschutzHeadline {
    margin: 0px;
  }