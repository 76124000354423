.kidsArea {
  height: 490px;
  background-color: rgba(251, 237, 113, 1);
}

.kidsSection {
  width: auto;
  height: 370px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}

.kidsMenuPic {
  height: 360px;
}

.kidsMenuLogo {
  width: 400px;
}

.kidsText {
  color: rgba(255, 123, 115, 1);
  width: 423px;
  height: 190px;
  font-size: 18px;
  text-align: center;
}

.kidsArticle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 50px;
}

.weHaveA {
  margin-bottom: 20px;
  margin-top: 70px;
}

@media (max-width: 1050px) {
  .kidsArea {
    height: 475px;
  }
  .kidsSection {
    width: auto;
    justify-content: center;
    padding-top: 50px;
  }
  .weHaveA {
    width: 220px;
  }
  .kidsMenuLogo {
    width: 280px;
  }
  .kidsMenuPic {
    height: 330px;
    margin-right: 10px;
  }
  .kidsArticle {
    margin-right: 40px;
  }
}

@media (max-width: 950px) {
  .kidsText {
    width: 300px;
    font-size: 16px;
  }
}

@media (max-width: 820px) {
  .kidsSection {
    padding: 20px;
  }
  .kidsArea {
    height: 415px;
  }
  .weHaveA {
    width: 200px;
    margin-bottom: 10px;
    margin-top: 70px;
  }
  .kidsMenuLogo {
    width: 260px;
  }
  .kidsMenuPic {
    height: 300px;
    margin-right: 10px;
  }
  .kidsText {
    width: 280px;
    font-size: 16px;
  }
  .kidsArticle {
    margin-right: 30px;
  }
}

@media (max-width: 750px) {
  .kidsArea {
    height: 375px;
  }
  .kidsSection {
    padding: 0px;
  }
  .weHaveA {
    width: 180px;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .kidsMenuLogo {
    width: 240px;
  }
  .kidsMenuPic {
    height: 280px;
  }
  .kidsText {
    width: 250px;
    height: auto;
    font-size: 14px;
  }
  .kidsArticle {
    margin-right: 25px;
  }
}

@media (max-width: 670px) {
  .kidsSection {
    height: auto;
    flex-direction: column;
    padding-top: 40px;
  }
  .kidsArea {
    height: 820px;
  }

  .kidsMenuPic {
    height: 360px;
  }

  .kidsMenuLogo {
    width: 380px;
  }

  .kidsText {
    color: rgba(255, 123, 115, 1);
    width: 423px;
    height: 95px;
    font-size: 16px;
    text-align: center;

  }

  .kidsArticle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 0px;
  }

  .weHaveA {
    margin-bottom: 20px;
  }
}

@media (max-width: 514px) {
  .kidsArea {
    height: 700px
  }
  .weHaveA {
    width: 180px;
    margin-bottom: 20px;
  }
  .kidsMenuLogo {
    width: 320px;
  }
  .kidsMenuPic {
    height: 270px;
    margin-right: 0;
  }
  .kidsText {
    width: 320px;
    height: 105px;
    font-size: 14px;
  }
  .kidsArticle {
    margin-right: 0px;
  }
  .kidsSection {
    padding-top: 35px;
  }
}

@media (max-width: 400px) {
  .kidsArea {
    height: 590px
  }
  .weHaveA {
    width: 160px;
    margin-bottom: 10px;
  }
  .kidsMenuLogo {
    width: 255px;
  }
  .kidsMenuPic {
    height: 215px;
  }
  .kidsText {
    width: 240px;
    height: 110px;
    font-size: 13px;
  }
  .kidsSection {
    padding-top: 25px;
  }
}
