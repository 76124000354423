.contactArea {
  height: 660px;
  background-color: rgba(255, 166, 129, 1);
}

.contactSection {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contactCollection {
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 20px;
}

.contactUnit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.contactColumn {
  width: 138px;
  height: fit-content;
  background-color: white;
  border-radius: 7px;
}

.contactHeadline {
  color: white;
  font-size: 20px;
  font-weight: bolder;
}

.contactText {
  color: black;
  font-size: 13px;
  padding: 10px;
  text-align: center;
  margin: 0px;
}

.socialMediaSection {
  width: 600px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 30px;
}

.insta {
  width: 88px;
}

.facebook {
  width: 105px;
}

@media (max-width: 520px) {
  .contactArea {
    height: 630px;
  }
  .contactSection {
    padding-top: 40px;
  }
  .contactCollection {
    justify-content: center;
  }
  .contactUnit {
    padding: 0px 20px 0px;
  }

  .contactColumn {
    width: 138px;
    height: fit-content;
    background-color: white;
    border-radius: 7px;
  }

  .socialMediaSection {
    justify-content: center;
  }

  .insta {
    width: 88px;
    padding: 0px 45px 0px;
  }

  .facebook {
    width: 105px;
    padding: 0px 45px 0px;
  }
}

@media (max-width: 380px) {
  .contactArea {
    height: 610px;
  }
  .contactSection {
    padding-top: 40px;
  }

  .socialMediaSection {
    width: auto;
  }

  .contactCollection {
    width: auto;
  }

  .contactColumn {
    width: 120px;
  }

  .contactText {
    font-size: 12px;
  }
  .contactUnit {
    padding: 0px 10px 0px;
  }
  .contactHeadline {
    margin: 10px 0 10px;
  }

  .socialMediaSection {
    justify-content: center;
  }

  .insta {
    width: 88px;
    padding: 0px 25px 0px;
  }

  .facebook {
    width: 105px;
    padding: 0px 25px 0px;
  }
}
