.drinksArea {
  height: 660px;
  background-color: rgba(211, 253, 220, 1);
}

.drinksSection {
  width: auto;
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding-top: 110px;
}

.drinksCollection {
  width: auto;
  height: 370px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding-top: 65px;
}

.homemadeRefreshments {
  margin-right: 100px;
}

.classicMilkshakes {
  margin-right: 50px;
}

@media (max-width: 1050px) {
  .drinksSection {
    padding-top: 95px;
  }
  .drinksArea {
    height: 600px;
  }
  .drinksCollection {
    padding-top: 40px;
    margin: 0px 60px 0px 60px;
    justify-content: center;
  }
  .weHave {
    width: 180px;
  }
  .homemadeRefreshments {
    height: 400px;
    margin-right: 50px;
  }

  .classicMilkshakes {
    height: 400px;
    margin-right: 30px;
  }
}

@media (max-width: 920px) {
  .drinksArea {
    height: 595px;
  }
  .drinksCollection {
    padding-top: 30px;
    margin: 0px 50px 0px 50px;
  }
  .homemadeRefreshments {
    height: 380px;
    margin-right: 30px;
  }
  .classicMilkshakes {
    height: 380px;
    margin-right: 40px;
  }
}

@media (max-width: 820px) {
  .drinksArea {
    height: 580px;
  }
  .drinksSection {
    padding-top: 85px;
  }
  .weHave {
    margin: 0px;
    width: 170px;
  }
  .drinksCollection {
    padding-top: 25px;
    margin: 0px;
  }
  .homemadeRefreshments {
    height: 370px;
    margin-right: 0px;
  }
  .classicMilkshakes {
    height: 370px;
    margin-right: 50px;
  }
}

@media (max-width: 770px) {
  .drinksArea {
    height: 1160px;
  }
  .drinksSection {
    padding-top: 35px;
    height: auto;
  }
  .weHave {
    width: 190px;
  }
  .drinksCollection {
    height: auto;
    flex-direction: column;
    padding-top: 0px;
    margin: 0px;
  }
  .homemadeRefreshments {
    height: 500px;
    margin-right: 0px;
  }
  .classicMilkshakes {
    height: 500px;
    margin-right: 50px;
  }
}

@media (max-width: 670px) {
  .drinksArea {
    height: 935px;
  }
  .drinksSection {
    padding-top: 30px;
  }
  .weHave {
    width: 177px;
  }
  .homemadeRefreshments {
    height: 370px;
    margin-right: 0px;
  }
  .classicMilkshakes {
    height: 370px;
    margin-right: 50px;
  }
}

@media (max-width: 514px) {
  .drinksArea {
    height: 850px;
  }
  .drinksSection {
    padding-top: 25px;
  }
  .weHave {
    width: 170px;
  }
  .homemadeRefreshments {
    height: 330px;

  }
  .classicMilkshakes {
    height: 330px;
  }
}

@media (max-width: 430px) {
  .drinksArea {
    width: auto;
    height: 665px;
  }
  .drinksSection {
    padding-top: 15px;
  }
  .weHave {
    width: 150px;
  }
  .homemadeRefreshments {
    width: auto;
    height: 250px;

  }
  .classicMilkshakes {
    width: auto;
    height: 250px;
  }
}
