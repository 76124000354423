.reservationsArea {
  height: 285px;
  background-color: rgba(117, 209, 221, 1);
  border: 12px solid rgba(240, 102, 143, 1);
  scroll-margin-top: 250px;
}

.reservationsSection {
  
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding-top: 50px;
}

.futureBoy {
    height: 190px;
}

.forReservationsCall {
    height: 165px;
}

.robotoFont {
  font-family: "Roboto";
}

@media (max-width: 800px) {
  .reservationsArea {
    height: 270px;
  }
  .reservationsSection {
    padding-top: 35px;
  }
  .futureBoy {
    height: 165px;
  }
  .forReservationsCall {
    height: 150px;
}
}

@media (max-width: 710px) {
  .reservationsArea {
    height: 245px;
  }
  .reservationsSection {
    padding-top: 25px;
  }
  .futureBoy {
    height: 140px;
  }
  .forReservationsCall {
    height: 130px;
    padding-bottom: 10px;
}
}

@media (max-width: 618px) {
  .reservationsArea {
    height: 215px;
  }
  .reservationsSection {
    padding-top: 10px;
  }
  .futureBoy {
    height: 120px;
  }
  .forReservationsCall {
    height: 110px;
    padding-bottom: 10px;
}
}

@media (max-width: 535px) {
  .reservationsArea {
    height: 345px;
  }
  .reservationsSection {
    height: auto;
    padding-top: 10px;
    flex-direction: column;
  }
  .futureBoy {
    height: 150px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .forReservationsCall {
    height: 110px;
    padding-bottom: 0px;
}
}

@media (max-width: 420px) {
  .reservationsArea {
    height: 285px;
  }
  .reservationsSection {
    padding-top: 5px;
  }
  .futureBoy {
    height: 130px;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .forReservationsCall {
    height: 80px;
    padding-bottom: 0px;
}
}