.header {
  width: auto;
  height: 112px;
  background-color: rgba(56, 56, 56, 1);
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  /* box-shadow: 0px 6px 10px -1px rgba(0,0,0,0.64); */
  /* border-bottom: 0.5rem solid rgba(255, 199, 199, 1); */
}

.headerLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: auto;
  height: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  margin-bottom: 8px;
}

.alienHead {
  width: 45px;
  height: 51px;
  margin-bottom: 2px;
}

.logoSubtitle {
  font-size: 10px;
}

.headerTitle {
  margin-top: 6px;
}

.nav {
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerSocialMedia {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
}

.insta {
  /* height: 50px;
  width: 50px; */
  margin-left: 10px;
  /* margin-top: 10px; */
  margin-right: 30px;
  cursor: pointer;
}

.facebook {
  /* height: 66px;
  width: 66px; */
  cursor: pointer;
}

.headerRight {
  width: 100px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px;
}

.bigPyramid {
  cursor: pointer;
  transition: all 2s ease-out;
}

.bigPyramid:hover {
  -webkit-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

@media (max-width: 798px) {
  .headerRight {
    margin-right: 20px;
  }
  .insta {
    margin-left: 0px;
    margin-right: 20px;
    cursor: pointer;
  }
}

@media (max-width: 480px) {
  .logoSubtitle {
    text-align: center;
    font-size: 10px;
    width: 60px;
  }
  .headerTitle {
    margin-top: 6px;
    width: 110px;
    height: auto;
  }
  .headerSocialMedia {
    margin-right: 20px;
  }
}

@media (max-width: 440px) {
  .headerSocialMedia {
    display: none;
  }
}

@media (max-width: 340px) {
  .logo {
    width: auto;
    height: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 8px;
  }
.headerRight {
  margin-right: 12px;
}
}