.impressum {
    background-color: rgba(56, 56, 56, 1);
    color: white;
  }
  
  .impressumContainer {
    padding: 25px;
  }
  
  .impressumHeadline {
    margin: 0px;
  }