.dropdownBox {
  position: absolute;
  right: 30px;
  top: 70px;
  width: 120px;
  height: 160px;
  background-color: rgba(56, 56, 56, 1);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-top: 30px;
}

.dropdownContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.navLinks {
  font-size: 14px;
  padding: 5px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.navLinks:hover {
  color: rgba(255, 123, 115, 1);
}

.navLinks:active {
  font-size: 15px;
  padding: 4px;
}

@media (max-width:798px) {
  .dropdownBox {
   width: 130px;
   right: 0px;
   border-bottom-right-radius: 0px;
  }
 }

 @media (max-width:360px) {
  .dropdownBox {
   width: 120px;
   right: 0px;
   border-bottom-right-radius: 0px;
  }
 }