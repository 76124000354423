.burgerLoveArea {
  height: 530px;
  background-color: rgba(72, 182, 189, 1);
}

.loveSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding-top: 45px;
}

.loveCollection {
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  padding-top: 30px;
}

.elMuerteDelTorro {
  margin-left: 30px;
  height: 315px;
}

.avocadoLover {
  margin-right: 60px;
  height: 300px;
}

@media (max-width: 1050px) {
  .loveCollection {
    width: auto;
  }
  .elMuerteDelTorro {
    margin-left: 0px;
    margin-right: 20px;
    height: 315px;
  }
  .avocadoLover {
    margin-right: 20px;
    height: 300px;
  }
}

@media (max-width: 670px) {
  .burgerLoveArea {
    height: 470px;
  }
  .loveSection {
    padding-top: 35px;
  }
  .unlimited {
    width: 480px;
  }
  .elMuerteDelTorro {
    margin-right: 20px;
    height: 260px;
  }
  .avocadoLover {
    margin-right: 20px;
    height: 250px;
  }
}

@media (max-width: 560px) {
  .burgerLoveArea {
    height: 400px;
  }
  .loveSection {
    padding-top: 25px;
  }
  .loveCollection {
    padding-top: 10px;
  }
  .unlimited {
    width: 400px;
  }
  .elMuerteDelTorro {
    margin-right: 10px;
    height: 230px;
  }
  .avocadoLover {
    margin-right: 15px;
    height: 220px;
  }
}

@media (max-width: 470px) {
  .burgerLoveArea {
    height: 565px;
  }
  .loveSection {
    padding-top: 15px;

  }
  .loveCollection {
    padding-top: 0px;
    flex-direction: column-reverse;
  }
  .unlimited {
    width: 290px;
  }
  .elMuerteDelTorro {
    margin-right: 0px;
  
    height: 200px;
  }
  .avocadoLover {
    margin-right: 15px;
    padding-bottom: 25px;
    height: 200px;
  }
}
