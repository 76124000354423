.home {
  width: auto;
  height: 750px;
  background-color: rgba(133, 200, 188, 1);
  scroll-margin-top: 200px;
}

.welcome {
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.welcomeMain {
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
}

.welcomeTypo {
  padding: 50px;
}

.toTheUniverse {
  padding: 50px;
}

.veganDino {
  height: auto;
  width: 400px;
  margin-left: 150px;
}

.downloadSection {
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.weGotBurgers {
  width: 350px;
  margin-bottom: 40px;
  margin-right: 60px;
}

.downloadArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.mrSalato {
  height: 100px;
  margin-left: 20px;
  align-self: flex-end;
  margin-bottom: 20px;
}

.downloadButtons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.downloadOur {
  margin-bottom: 20px;
  align-self: flex-end;
}

.buttonZone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.button {
  font-weight: bold;
  font-size: 18px;
  border: none;
  color: white;
  width: 190px;
  height: 46px;
  background-color: rgba(255, 138, 131, 1);
  border-radius: 7px;
  margin-left: 20px;
  box-shadow: 2px 2px 6px -1px rgba(92, 87, 92, 1);
  cursor: pointer;
}

.button:hover {
  background-color: rgba(255, 123, 115, 1);
}

.button:active {
  box-shadow: none;
}

.delivery {
  height: 130px;
  width: 900px;
  background-color: rgba(232, 214, 255, 1);
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  margin-top: 30px;
}

.astralCat {
  width: 127px;
  height: 80px;
  margin-left: 20px;
}

#onlineButton {
  width: 180px;
}

@media (max-width: 1030px) {
  .home {
    height: 650px;
  }
  .welcome,
  .welcomeMain {
    width: auto;
  }
  .welcomeTypo {
    width: 360px;
    padding: 30px;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 20px;
  }

  .toTheUniverse {
    height: 56px;
    padding: 30px;
    padding-left: 0px;
    padding-right: 22px;
    margin-left: 20px;
  }
  .veganDino {
    width: auto;
    height: 300px;
    margin-left: 150px;
  }
  .weGotBurgers {
    width: 300px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 0px;
  }
  .downloadArea {
    margin-right: 30px;
  }

  .mrSalato {
    margin-right: 50px;
    height: 80px;
  }

  .downloadButtons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .downloadOur {
    width: 180px;
    align-self: flex-end;
  }

  .buttonZone {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  .button {
    width: 180px;
    height: 46px;
    margin-left: 20px;
  }

  .delivery {
    height: 120px;
    width: 840px;
  }

  .forFreeDeliveryCall {
    width: 340px;
  }

  .or {
    width: 35px;
  }

  .astralCat {
    width: auto;
    height: 70px;
    margin: 0px;
  }

  #onlineButton {
    width: 180px;
    margin: 0px;
  }
}

@media (max-width: 900px) {
  .home {
    height: 600px;
  }
  .welcomeTypo {
    height: 50px;
    padding: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .toTheUniverse {
    height: 50px;
    padding: 30px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .veganDino {
    width: auto;
    height: 280px;
    margin-left: 150px;
  }
  .downloadButtons {
    margin-right: 10px;
  }

  .delivery {
    height: 110px;
    width: 750px;
  }

  .forFreeDeliveryCall {
    width: 300px;
  }

  .or {
    width: 30px;
  }

  .astralCat {
    width: auto;
    height: 65px;
    margin: 0px;
  }

  #onlineButton {
    width: 180px;
    margin: 0px;
  }
}

@media (max-width: 850px) {
  .home {
    width: auto;
    height: 520px;
  }
  .welcomeTypo {
    height: 35px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .toTheUniverse {
    height: 35px;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 30px;
  }
  .downloadSection {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .downloadOur {
    width: 160px;
  }
  .button {
    width: 160px;
    height: 40px;
    margin-left: 20px;
    font-size: 16px;
  }
  .veganDino {
    width: auto;
    height: 250px;
    margin-left: 70px;
  }
  .weGotBurgers {
    width: 290px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
  }
  .mrSalato {
    height: 80px;
    margin-left: 20px;
    padding-bottom: 20px;
  }
  .delivery {
    height: 85px;
    width: 600px;
  }
  .arrowToButton {
    display: none;
  }
  .forFreeDeliveryCall {
    width: 250px;
  }

  .or {
    width: 22px;
  }

  .astralCat {
    width: auto;
    height: 52px;
  }

  #onlineButton {
    width: 140px;
    height: 40px;
    margin: 0px;
  }
}
@media (max-width: 670px) {
  .home {
    width: auto;
    height: 965px;
  }
  .welcome {
    height: 190px;
    width: auto;
    flex-direction: column;
    padding-top: 8px;
  }
  .welcomeMain {
    flex-direction: column;
  }
  .welcomeTypo {
    height: 100px;
    padding-top: 25px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 15px;
    margin: 0px;
  }

  .toTheUniverse {
    padding-top: 0px;
    padding-right: 0px;
    height: 80px;
    margin: 0px;
  }
  .downloadSection {
    height: 150px;
    width: fit-content;
    align-self: center;
  }
  .downloadArea {
    display: flex;
    flex-direction: column;
  }
  .downloadOur {
    display: none;
    width: 160px;
  }
  .downloadButtons {
    flex-direction: row;
    margin: 0px;
    margin-top: 10px;
  }
  .button {
    width: 142px;
    height: 40px;
    margin-left: 25px;
    font-size: 14px;
  }
  .veganDino {
    height: 290px;
    margin-top: 20px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .weGotBurgers {
    width: 340px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
    margin-bottom: 10px;
    align-self: center;
  }
  .mrSalato {
    display: none;
  }
  .delivery {
    height: 230px;
    width: 310px;
    flex-direction: column;
    margin-top: 0px;
  }
  .forFreeDeliveryCall {
    padding-top: 10px;
    margin-left: 16px;
    width: 290px;
  }
  .or {
    width: 22px;
  }
  .astralCat {
    width: auto;
    height: 52px;
  }
  #onlineButton {
    width: 140px;
    height: 40px;
    margin-bottom: 21px;
  }
}

@media (max-width: 498px) {
  .home {
    width: auto;
    height: 940px;
  }
  .welcome {
    height: 200px;
    flex-direction: column;
  }
  .welcomeMain {
    flex-direction: column;
  }
  .welcomeTypo {
    height: 50px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
  }

  .toTheUniverse {
    padding-top: 0px;
    height: 50px;
    margin: 0px;
  }
  .downloadSection {
    height: 150px;
    align-self: center;
  }
  .downloadArea {
    display: flex;
    flex-direction: column;
  }
  .downloadOur {
    display: none;
    width: 160px;
  }
  .downloadButtons {
    flex-direction: row;
    margin: 0px;
    margin-top: 10px;
  }
  .button {
    width: 140px;
    height: 40px;
    margin-left: 20px;
    font-size: 14px;
  }
  .veganDino {
    height: 260px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .weGotBurgers {
    width: 300px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
    margin-bottom: 10px;
    align-self: center;
  }
  .mrSalato {
    display: none;
  }
  .delivery {
    height: 230px;
    width: 300px;
    flex-direction: column;
    margin-top: 0px;
  }
  .forFreeDeliveryCall {
    padding-top: 8px;
    margin-left: 16px;
    width: 290px;
  }
  .or {
    width: 22px;
  }
  .astralCat {
    width: auto;
    height: 52px;
  }
  #onlineButton {
    width: 140px;
    height: 40px;
    margin-bottom: 18px;
  }
}

@media (max-width: 398px) {
  .home {
    height: 880px;
  }
  .welcome {
    height: 160px;
  }
  .welcomeTypo {
    height: 46px;
    width: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .toTheUniverse {
    padding-right: 0px;
    width: auto;
    height: 46px;
  }
  .downloadSection {
    height: 150px;
    align-self: center;
  }
  .downloadArea {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }
  .downloadOur {
    display: none;
    width: 160px;
  }
  .downloadButtons {
    flex-direction: row;
    margin: 0px;
    margin-top: 10px;
  }
  .button {
    width: 126px;
    height: 40px;
    margin-left: 20px;
    font-size: 13px;
  }
  .veganDino {
    height: 240px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .weGotBurgers {
    width: 270px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
    margin-bottom: 5px;
    align-self: center;
  }
  .delivery {
    height: 220px;
    width: 270px;
    flex-direction: column;
    margin-top: 8px;
  }
  .forFreeDeliveryCall {
    padding-top: 4px;
    margin-left: 16px;
    width: 265px;
  }
  .or {
    width: 22px;
  }
  .astralCat {
    width: auto;
    height: 52px;
  }
  #onlineButton {
    width: 140px;
    height: 40px;
    margin-bottom: 18px;
  }
}

@media (max-width: 354px) {
  .home {
    height: 790px;
  }
  .welcome {
    height: 140px;
  }
  .welcomeTypo {
    height: 43px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .toTheUniverse {
    height: 43px;
  }
  .downloadSection {
    height: 150px;
    align-self: center;
  }
  .downloadArea {
    display: flex;
    flex-direction: column;
    margin-right: 24px;
  }
  .downloadOur {
    display: none;
    width: 160px;
  }
  .downloadButtons {
    flex-direction: row;
    margin: 0px;
    margin-top: 10px;
  }
  .button {
    width: 111px;
    height: 44px;
    margin-left: 20px;
    font-size: 13px;
  }
  .veganDino {
    height: 200px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .weGotBurgers {
    width: 240px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0;
    margin-bottom: 5px;
    align-self: center;
  }
  .delivery {
    height: 220px;
    width: 240px;
    flex-direction: column;
    margin-top: 8px;
  }
  .forFreeDeliveryCall {
    padding-top: 0px;
    margin-left: 16px;
    width: 230px;
  }
  .or {
    width: 22px;
  }
  .astralCat {
    width: auto;
    height: 49px;
  }
  #onlineButton {
    width: 140px;
    height: 40px;
    margin-bottom: 20px;
  }
}
