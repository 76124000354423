.franchiseArea {
  height: 20px;
  background-color: rgb(255, 99, 99);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 5px;
  margin: auto;
  color: yellow
}

@media (max-width: 370px) {
  .franchiseArea {
    font-size: 12px;
  }
}
