.veganArea {
  height: 490px;
  background-color: rgba(237, 193, 241, 1);
  scroll-margin-top: 100px;
}

.veganSection {
  width: auto;
  height: 370px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  padding-right: 0px;
}

.veganotti {
  height: 370px;
}

.veganArticle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 0px;
}

.veganText {
  color: white;
  width: 423px;
  height: 190px;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 1030px) {
  .veganSection {
    width: auto;
    padding-top: 40px;
  }
  .veganArea {
    height: 450px;
  }
  .veganotti {
    height: 320px;
  }
}

@media (max-width: 855px) {
  .veganotti {
    height: 270px;
  }
  .veganSection {
    padding-top: 15px;
  }
  .veganArea {
    height: 385px;
  }
  .weAreVeganFriendly {
    width: 320px;
  }
  .veganArticle {
    width: 360px;
  }
  .veganText {
    font-size: 15px;
    width: 350px;
  }
}

@media (max-width: 670px) {
  .veganSection {
    padding-top: 15px;
  }
  .veganArea {
    height: 385px;
  }
  .veganotti {
    margin-left: 20px;
    height: 260px;
  }
  .weAreVeganFriendly {
    width: 320px;
  }
  .veganArticle {
    margin-right: 20px;
    width: 360px;
  }
  .veganText {
    font-size: 15px;
    width: 350px;
  }
}


@media (max-width: 670px) {
  .veganSection {
    padding-top: 6px;
    justify-content: center;
  }
  .veganArea {
    height: 355px;
  }
  .veganotti {
    margin-left: 0;
    margin-top: 10px;
    height: 220px;
  }
  .weAreVeganFriendly {
    width: 260px;
  }
  .veganArticle {
    margin-right: 40px;
    width: 260px;
  }
  .veganText {
    font-size: 13px;
    width: 300px;
  }
}


@media (max-width: 514px) {
  .veganSection {
    height: fit-content;
    flex-direction: column;
    padding-top: 20px;
    justify-content: center;
  }
  .veganArea {
    height: 570px;
  }
  .veganotti {
    margin-left: 0;
    margin-top: 25px;
    height: 220px;
  }
  .weAreVeganFriendly {
    width: 260px;
  }
  .veganArticle {
    margin-right: 0px;
    width: 260px;
  }
  .veganText {
    margin: 0px;
    font-size: 13px;
    width: 300px;
  }
}

@media (max-width: 380px) {
  .veganSection {
    height: fit-content;
    flex-direction: column;
    padding-top: 20px;
    justify-content: center;
  }
  .veganArea {
    height: 575px;
  }
  .veganotti {
    margin-left: 0;
    margin-top: 15px;
    height: 190px;
  }
  .weAreVeganFriendly {
    width: 220px;
  }
  .veganText {
    margin: 0px;
    font-size: 13px;
    width: 220px;
  }
}